body {
  background: transparent;
} 

body:after {
  content: '';
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  display: block;
  background-image: url(./utils/images/background2.webp);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(16, 133, 133, 0.6);
}


body::before {
  content: '';
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
}

@media (hover: none) {
  body {
    background-attachment: fixed;
    background-repeat: repeat-y;
  }
}

#page-wrap {
  height: 100%;
  padding: 3em 2em;
  text-align: center;
}