.home-row {
    display: flex;
    align-items: center;
}

.contact-row {
    display: flex;
    align-items: center;
    position: absolute;
    right: 5%;
    top: 5%;
}

.home-container, .contact-container {
    padding: 2%;
    overflow-x: hidden; 
    overflow-y: hidden;
    width: 100%;
    margin-top: 8vh;
}

.center-container {
    display: flex;
    align-items: center;
}

.contact-container {
    height: 100%;
}

.home-container {
    min-height: 80vh;   
}
